<template>
  <iframe ref="iframeRef" :src="url" />
</template>

<script>
import { getCurrentInstance, onUnmounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';

export default {
  setup() {
    const { proxy } = getCurrentInstance();
    const { params, query } = useRoute();
    const router = useRouter();
    const store = useStore();
    const { tenant, token, language, apps } = store.state.account;
    const iframeRef = ref();
    const url = ref(
      query.path.startsWith('http')
        ? query.path
        : `${apps.find(i => i.id === params.app).entryUrl}#${query.path}`
    );
    const handleMessage = ({ data }) => {
      console.log('来自子应用的数据：', data);
      if (data.init) {
        // 子应用已加载，向子应用发送数据
        iframeRef.value.contentWindow.postMessage(
          {
            tenant,
            token,
            language,
            eventId: params.eventId,
            campaignId: params.campaignId,
          },
          '*'
        );
      } else if (data.noToken) {
        // 子应用验证token失败时，跳转登录页面
        router.push('/login');
      } else if (data.breadcrumbs) {
        // 导航
        store.commit('common/setBreadcrumbs', data.breadcrumbs);
      } else if (data.iframePath) {
        // iframe内部页面跳转后，更改浏览器地址栏
        window.history.pushState(
          {},
          '',
          `${window.location.href.split('?')[0]}?path=${data.iframePath}`
        );
      }
    };
    // 点击面包屑，向子应用发消息，跳转页面
    proxy.$bus.on('ISSIFRAMENAV', item =>
      iframeRef.value?.contentWindow.postMessage({ goTo: item }, '*')
    );
    window.addEventListener('message', handleMessage);
    onUnmounted(() => {
      window.removeEventListener('message', handleMessage);
      store.commit('common/setBreadcrumbs', null);
    });
    return { iframeRef, url };
  },
};
</script>

<style lang="less" scoped>
iframe {
  width: 100%;
  height: 99%;
  border: 0;
}
</style>
